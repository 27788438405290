import {
  UnderwearCategory,
  UnderwearCategoryType,
} from '@repo/types/src/underwear';
import { z } from 'zod';

const requiredMessage = 'Champ requis';

const [firstUnderwearCategoryKey, ...otherUnderwearCategoryKeys] = Object.keys(
  UnderwearCategory,
) as UnderwearCategoryType[];

export const schema = z.object({
  name: z.string().trim().min(1, requiredMessage),
  file: z.instanceof(File),
  category: z.enum([firstUnderwearCategoryKey, ...otherUnderwearCategoryKeys]),
  sortOrder: z.coerce.number().min(0),
});

export type UnderwearSchemaType = z.infer<typeof schema>;
