import { createBrowserRouter } from 'react-router-dom';
import { AppLayout } from '@/layouts/AppLayout';
import { DashboardPage } from '@/pages/DashboardPage';
import { LoginPage } from '@/pages/LoginPage';
import { Paths } from '@/routes/paths';
import { PrivateRoute } from '@/routes/private-route';
import { PointOfSalesPage } from '@/pages/PointOfSalesPage';
import { UnderwearPage } from '@/pages/UnderwearPage';

export const router = createBrowserRouter([
  {
    path: Paths.Dashboard,
    element: (
      <PrivateRoute>
        <AppLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: Paths.Dashboard,
        element: <DashboardPage />,
      },
      {
        path: Paths.PointOfSales,
        element: <PointOfSalesPage />,
      },
      {
        path: Paths.Underwear,
        element: <UnderwearPage />,
      },
    ],
  },
  {
    path: Paths.Login,
    element: <LoginPage />,
  },
]);
