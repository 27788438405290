import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts';
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  type ChartConfig,
} from '@/components/ui/chart';
import { useGetAgeDistribution } from '@/api/dashboard/dashboard.hooks';
import { ChartSkeleton } from '@/components/dashboard/chart-skeleton';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

const chartConfig = {
  users: {
    label: 'Utilisatrices',
    color: 'hsl(var(--chart-1))',
  },
} satisfies ChartConfig;

export const AgeBarChart = () => {
  const { ageDistribution, isError, isLoading } = useGetAgeDistribution();

  return (
    <div className="w-full h-full">
      {isLoading || isError ? (
        <ChartSkeleton />
      ) : (
        <Card className="h-full">
          <CardHeader>
            <CardTitle>
              Répartition des utilisatrices par tranches d'âge
            </CardTitle>
          </CardHeader>
          <CardContent>
            <ChartContainer
              config={chartConfig}
              className="mx-auto w-full aspect-square"
            >
              <BarChart
                accessibilityLayer
                data={ageDistribution}
                margin={{
                  top: 20,
                }}
              >
                <CartesianGrid vertical={false} />
                <XAxis
                  dataKey="ageRange"
                  tickLine={false}
                  tickMargin={10}
                  axisLine={false}
                  interval={0}
                  height={55}
                  tick={(props) => {
                    const { x, y, payload } = props;
                    return (
                      <g transform={`translate(${x},${y})`}>
                        <text
                          x={0}
                          y={0}
                          dy={16}
                          textAnchor="end"
                          transform="rotate(-60)"
                          fill="#666"
                        >
                          {payload.value}
                        </text>
                      </g>
                    );
                  }}
                />
                <YAxis
                  dataKey="users"
                  tickLine={false}
                  tickMargin={10}
                  axisLine={false}
                  allowDecimals={false}
                />
                <ChartTooltip content={<ChartTooltipContent />} />

                <Bar dataKey="users" fill="var(--color-users)" radius={4} />
              </BarChart>
            </ChartContainer>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
