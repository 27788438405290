import { useMutation, useQuery } from '@tanstack/react-query';
import { UnderwearRequest, UnderwearResponse } from '@repo/types/src/underwear';
import { queryClient } from '@/config/query-client.config';
import {
  createUnderwear,
  deleteUnderwear,
  getAllUnderwears,
} from '@/api/underwear/underwear.queries';
import { underwearsQueryKeys } from '@/api/underwear/underwear.query-keys';

export const useGetUnderwears = () => {
  const {
    data: underwears,
    isError,
    isLoading,
  } = useQuery({
    queryKey: underwearsQueryKeys.all,
    queryFn: getAllUnderwears,
  });

  return { underwears, isError, isLoading };
};

export const useCreateUnderwear = () => {
  const mutation = useMutation({
    mutationFn: (payload: UnderwearRequest) => createUnderwear(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: underwearsQueryKeys.all }),
  });

  return mutation;
};

export const useDeleteUnderwear = () => {
  const mutation = useMutation({
    mutationFn: (id: number) => deleteUnderwear(id),
    onSuccess: (data) => {
      queryClient.setQueryData<UnderwearResponse[]>(
        underwearsQueryKeys.all,
        (oldData) =>
          oldData?.filter((pointOfSale) => pointOfSale.id !== data.id),
      );
    },
  });

  return mutation;
};
