import { Skeleton } from '../ui/skeleton';

export const ChartSkeleton = () => {
  return (
    <div className="flex flex-col space-y-3">
      <div className="space-y-2">
        <Skeleton className="h-4 w-[80%]" />
        <Skeleton className="h-4 w-[90%]" />
      </div>
      <Skeleton className="h-[400px] rounded-xl" />
    </div>
  );
};
