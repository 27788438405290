import { Pie, PieChart } from 'recharts';
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
  type ChartConfig,
} from '@/components/ui/chart';
import { useGetDiseaseDistribution } from '@/api/dashboard/dashboard.hooks';
import { ChartSkeleton } from '@/components/dashboard/chart-skeleton';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';

const chartConfig = {
  users: {
    label: 'Utilisatrices',
  },
  ENDOMETRIOSIS: {
    label: 'Endométriose',
    color: 'hsl(var(--chart-2))',
  },
  UTERINE_FIBROIDS: {
    label: 'Fibromes utérins',
    color: 'hsl(var(--chart-4))',
  },
  COAGULATION_DISORDER: {
    label: 'Troubles de la coagulation',
    color: 'hsl(var(--chart-3))',
  },
  SOPK: {
    label: 'SOPK',
    color: 'hsl(var(--chart-1))',
  },
  THROMBOCYTOPENIA: {
    label: 'Thrombocytopénie',
    color: 'hsl(var(--chart-5))',
  },
  MEDICATION: {
    label: 'Médications',
    color: 'hsl(var(--chart-6))',
  },
  OTHER: {
    label: 'Autre',
    color: 'hsl(var(--chart-7))',
  },
  NO_DISEASE: {
    label: 'Pas de maladie',
    color: 'hsl(var(--chart-8))',
  },
} satisfies ChartConfig;

export const DiseasePieChart = () => {
  const { distribution, usersCount, isError, isLoading } =
    useGetDiseaseDistribution();

  const diseaseDistributionWithColors = distribution?.map((disease) => {
    return {
      ...disease,
      fill: `var(--color-${disease.gynecologicalDisease})`,
    };
  });

  return (
    <div className="w-full h-full">
      {isLoading || isError ? (
        <ChartSkeleton />
      ) : (
        <Card>
          <CardHeader>
            <CardTitle>Répartition des utilisatrices par maladies</CardTitle>
          </CardHeader>
          <CardContent>
            <ChartContainer
              config={chartConfig}
              className="mx-auto aspect-square"
            >
              <PieChart
                margin={{
                  top: 20,
                }}
              >
                <ChartTooltip
                  content={
                    <ChartTooltipContent
                      nameKey="gynecologicalDisease"
                      hideLabel
                    />
                  }
                />
                <Pie
                  data={diseaseDistributionWithColors}
                  dataKey="users"
                  labelLine={false}
                  label={({ payload, ...props }) => {
                    return (
                      <text
                        cx={props.cx}
                        cy={props.cy}
                        x={props.x}
                        y={props.y}
                        textAnchor={props.textAnchor}
                        dominantBaseline={props.dominantBaseline}
                        fill="hsla(var(--foreground))"
                      >
                        {usersCount && payload.users
                          ? `${Math.round((payload.users / usersCount) * 100)}%`
                          : ''}
                      </text>
                    );
                  }}
                  nameKey="gynecologicalDisease"
                />
                <ChartLegend
                  content={
                    <ChartLegendContent nameKey="gynecologicalDisease" />
                  }
                  className="flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center"
                />
              </PieChart>
            </ChartContainer>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
