// PrivateRoute.js
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '@/providers/auth.context';
import { Paths } from '@/routes/paths';

type Props = {
  children: ReactNode;
};
export const PrivateRoute = ({ children }: Props) => {
  const { accessToken } = useAuth();

  return accessToken ? children : <Navigate to={Paths.Login} replace />;
};
