export const ErrorScreen = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full gap-2">
      <h1 className="text-3xl font-bold text-center">Erreur</h1>
      <h2 className="text-center text-xl">
        Une erreur est survenue, veuillez contacter le service technique
      </h2>
    </div>
  );
};
