import axios, { AxiosInstance } from 'axios';
import { API_URL } from '@/config/axios.config';

export const publicAxiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

publicAxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (axios.isAxiosError(error))
      // eslint-disable-next-line no-param-reassign
      error.message = error.response?.data?.message;
    return Promise.reject(error);
  },
);
