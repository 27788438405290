type Props = {
  title: string;
  description?: string;
  children: React.ReactNode;
};

export const AppPageLayout = ({ title, description, children }: Props) => {
  return (
    <div className="flex flex-col h-screen w-full p-12 justify-start items-start overflow-hidden">
      <div className="flex flex-col items-start justify-start gap-2">
        <h1 className="text-3xl font-bold text-center">{title}</h1>
        {description && <h2 className="text-center text-xl">{description}</h2>}
      </div>
      <div className="h-full w-full">{children}</div>
    </div>
  );
};
