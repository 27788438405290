import { useQuery } from '@tanstack/react-query';
import {
  FlowIntensityType,
  GynecologicalDiseaseType,
  CyclePhaseType,
  TimeScale,
} from '@repo/types';
import { dashboardQueryKeys } from '@/api/dashboard/dashboard.query-keys';
import {
  getActiveUsersByTimeScale,
  getAgeDistribution,
  getAvgSessionDurationByTimeScale,
  getContraceptionDistribution,
  getDiseaseDistribution,
  getFeatureUsageDistribution,
  getFlowDistribution,
  getSymptomDistribution,
  getUnderwearWearingTimeDistribution,
} from '@/api/dashboard/dashboard.queries';

export const useGetAgeDistribution = () => {
  const {
    data: ageDistribution,
    isError,
    isLoading,
  } = useQuery({
    queryKey: dashboardQueryKeys.ageDistribution,
    queryFn: getAgeDistribution,
  });

  return { ageDistribution, isError, isLoading };
};

export const useGetFlowDistribution = () => {
  const {
    data: flowDistribution,
    isError,
    isLoading,
  } = useQuery({
    queryKey: dashboardQueryKeys.flowDistribution,
    queryFn: getFlowDistribution,
  });

  return { flowDistribution, isError, isLoading };
};

export const useGetContraceptionDistribution = () => {
  const {
    data: contraceptionDistribution,
    isError,
    isLoading,
  } = useQuery({
    queryKey: dashboardQueryKeys.contraceptionDistribution,
    queryFn: getContraceptionDistribution,
  });

  return { contraceptionDistribution, isError, isLoading };
};

export const useGetDiseaseDistribution = () => {
  const { data, isError, isLoading } = useQuery({
    queryKey: dashboardQueryKeys.diseaseDistribution,
    queryFn: getDiseaseDistribution,
  });

  const { distribution, usersCount } = data ?? {
    distribution: [],
    usersCount: 0,
  };

  return { distribution, usersCount, isError, isLoading };
};

export const useGetSymptomDistribution = (cyclePhase?: CyclePhaseType) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: cyclePhase
      ? dashboardQueryKeys.symptomDistributionWithParams(cyclePhase)
      : dashboardQueryKeys.symptomDistribution,
    queryFn: () => getSymptomDistribution(cyclePhase),
  });

  const { distribution, usersCount } = data ?? {
    distribution: [],
    usersCount: 0,
  };

  return { distribution, usersCount, isError, isLoading };
};

export const useGetUnderwearWearingTimeDistribution = (
  flow?: FlowIntensityType,
  disease?: GynecologicalDiseaseType,
  underwearId?: number,
) => {
  const {
    data: underwearWearingTimeDistribution,
    isError,
    isLoading,
  } = useQuery({
    queryKey:
      flow || disease || underwearId
        ? dashboardQueryKeys.underwearWearingTimeDistributionWithParams(
            flow,
            disease,
            underwearId,
          )
        : dashboardQueryKeys.underwearWearingTimeDistribution,
    queryFn: () =>
      getUnderwearWearingTimeDistribution(flow, disease, underwearId),
  });

  return {
    underwearWearingTimeDistribution,
    isError,
    isLoading,
  };
};

export const useGetFeatureUsageDistribution = () => {
  const {
    data: distribution,
    isError,
    isLoading,
  } = useQuery({
    queryKey: dashboardQueryKeys.featureUsageDistribution,
    queryFn: () => getFeatureUsageDistribution(),
  });

  return {
    distribution,
    isError,
    isLoading,
  };
};

export const useGetActiveUsersByTimeScale = (timeScale: TimeScale = 'week') => {
  const { data, isError, isLoading } = useQuery({
    queryKey: dashboardQueryKeys.activeUsersByTimeScale(timeScale),
    queryFn: () => getActiveUsersByTimeScale(timeScale),
  });

  return {
    distribution: data?.distribution,
    usersCount: data?.usersCount,
    isError,
    isLoading,
  };
};

export const useGetAvgSessionDurationByTimeScale = (
  timeScale: TimeScale = 'week',
) => {
  const {
    data: distribution,
    isError,
    isLoading,
  } = useQuery({
    queryKey: dashboardQueryKeys.avgSessionDurationByTimeScale(timeScale),
    queryFn: () => getAvgSessionDurationByTimeScale(timeScale),
  });

  return {
    distribution,
    isError,
    isLoading,
  };
};
