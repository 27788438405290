import {
  FlowIntensityType,
  GynecologicalDiseaseType,
  CyclePhaseType,
  TimeScale,
} from '@repo/types';

export const dashboardQueryKeys = {
  ageDistribution: ['ageDistribution'] as const,
  flowDistribution: ['flowDistribution'] as const,
  contraceptionDistribution: ['contraceptionDistribution'] as const,
  diseaseDistribution: ['diseaseDistribution'] as const,
  symptomDistribution: ['symptomDistribution'] as const,
  symptomDistributionWithParams: (cyclePhase?: CyclePhaseType) =>
    [...dashboardQueryKeys.symptomDistribution, { cyclePhase }] as const,
  underwearWearingTimeDistribution: [
    'underwearWearingTimeDistribution',
  ] as const,
  underwearWearingTimeDistributionWithParams: (
    flow?: FlowIntensityType,
    disease?: GynecologicalDiseaseType,
    underwearId?: number,
  ) =>
    [
      ...dashboardQueryKeys.underwearWearingTimeDistribution,
      { flow, disease, underwearId },
    ] as const,
  featureUsageDistribution: ['featureUsageDistribution'] as const,
  activeUsersByTimeScale: (timeScale: TimeScale) =>
    ['activeUsersByTimeScale', timeScale] as const,
  avgSessionDurationByTimeScale: (timeScale: TimeScale) =>
    ['avgSessionDurationByTimeScale', timeScale] as const,
};
