import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
} from 'recharts';
import { TimeScale } from '@repo/types/src/dashboard';
import { useState } from 'react';
import dayjs from 'dayjs';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';
import {
  ChartContainer,
  ChartTooltipContent,
  ChartTooltip,
  type ChartConfig,
} from '@/components/ui/chart';
import { useGetAvgSessionDurationByTimeScale } from '@/api/dashboard/dashboard.hooks';
import { ChartSkeleton } from '@/components/dashboard/chart-skeleton';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { TimeScaleFr } from '@/components/dashboard/utils';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

const commonChartConfig = {
  label: {
    color: 'white',
    label: 'Période',
  },
  sessionDuration: {
    label: "Durée d'un session en moyenne (minutes)",
    color: 'hsl(var(--chart-1))',
  },
};

const weekChartConfig = {
  0: { label: 'Lundi' },
  1: { label: 'Mardi' },
  2: { label: 'Mercredi' },
  3: { label: 'Jeudi' },
  4: { label: 'Vendredi' },
  5: { label: 'Samedi' },
  6: { label: 'Dimanche' },
} satisfies ChartConfig;

const monthChartConfig = {
  0: { label: 'S1' },
  1: { label: 'S2' },
  2: { label: 'S3' },
  3: { label: 'S4' },
  4: { label: 'S5' },
} satisfies ChartConfig;

const yearChartConfig = {
  0: { label: 'Janvier' },
  1: { label: 'Février' },
  2: { label: 'Mars' },
  3: { label: 'Avril' },
  4: { label: 'Mai' },
  5: { label: 'Juin' },
  6: { label: 'Juillet' },
  7: { label: 'Août' },
  8: { label: 'Septembre' },
  9: { label: 'Octobre' },
  10: { label: 'Novembre' },
  11: { label: 'Décembre' },
} satisfies ChartConfig;

export const SessionDurationBarChart = () => {
  const [timeScale, setTimeScale] = useState<TimeScale>('week');
  const { distribution, isError, isLoading } =
    useGetAvgSessionDurationByTimeScale(timeScale);

  const getChartConfig = () => {
    let timeScaleConfig: ChartConfig = weekChartConfig;
    if (timeScale === 'month') timeScaleConfig = monthChartConfig;
    if (timeScale === 'year') timeScaleConfig = yearChartConfig;

    return { ...commonChartConfig, ...timeScaleConfig };
  };

  const formatSecondsLabel = (value: number) => {
    return dayjs.duration(value, 'seconds').format('mm[min] ss[s]');
  };

  const formattedDistribution = distribution?.map(
    ({ timeKey, sessionDuration }) => {
      const chartConfig = getChartConfig();
      const configKey = timeKey.toString() as keyof typeof chartConfig;
      const config = chartConfig[configKey];

      return {
        timeKey: config?.label,
        sessionDuration,
      };
    },
  );

  const getAvgDuration = () => {
    const durations = formattedDistribution?.map(
      ({ sessionDuration }) => sessionDuration,
    );
    if (!durations) return 0;
    return Math.floor(
      durations.reduce((acc, curr) => acc + curr, 0) / durations.length,
    );
  };
  const avgDuration = getAvgDuration();

  return (
    <div className="w-full h-full">
      {isLoading || isError ? (
        <ChartSkeleton />
      ) : (
        <Card className="h-full">
          <CardHeader>
            <CardTitle>Temps moyen d'utilisation par session</CardTitle>
            <CardDescription>
              Temps moyen d'utilisation par session sur la période choisie : 30
              minutes
            </CardDescription>
            <div className="w-full flex flex-col xl:flex-row gap-4 items-end pt-3">
              <Select
                value={timeScale}
                onValueChange={(value) => setTimeScale(value as TimeScale)}
              >
                <SelectTrigger className="w-[250px]">
                  <SelectValue placeholder="Sélectionner une période" />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(TimeScaleFr).map(([key, value]) => (
                    <SelectItem key={key} value={key}>
                      {value}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </CardHeader>
          <CardContent>
            <ChartContainer config={getChartConfig()} className="max-h-[500px]">
              <BarChart
                accessibilityLayer
                data={formattedDistribution}
                layout="vertical"
                margin={{ top: 16, bottom: 18, left: 16, right: 16 }}
                maxBarSize={50}
              >
                <CartesianGrid horizontal={false} />
                <XAxis
                  type="number"
                  dataKey="sessionDuration"
                  label={{
                    value: "Durée moyenne d'utilisation par session (minutes)",
                    position: 'insideBottom',
                    dy: 20,
                  }}
                  tickLine={false}
                  tickMargin={10}
                  axisLine={false}
                  interval={0}
                  allowDecimals={false}
                  tickFormatter={(value) =>
                    dayjs
                      .duration(value as number, 'seconds')
                      .asMinutes()
                      .toFixed(1)
                  }
                />
                <YAxis
                  dataKey="timeKey"
                  type="category"
                  tickLine={false}
                  tickMargin={10}
                  axisLine={false}
                  hide
                  interval={0}
                />
                <ChartTooltip
                  cursor={false}
                  content={
                    <ChartTooltipContent
                      indicator="line"
                      labelFormatter={(value: string) => value}
                      valueFormatter={(value: ValueType) =>
                        formatSecondsLabel(value as number)
                      }
                    />
                  }
                />

                <Bar
                  dataKey="sessionDuration"
                  radius={4}
                  layout="vertical"
                  fill="var(--color-sessionDuration)"
                >
                  <LabelList
                    dataKey="timeKey"
                    position="insideLeft"
                    offset={8}
                    className="fill-[--color-label]"
                    fontSize={12}
                  />
                  <LabelList
                    dataKey="sessionDuration"
                    position="right"
                    offset={-80}
                    className="fill-[--color-label]"
                    fontSize={12}
                    formatter={formatSecondsLabel}
                  />
                </Bar>
              </BarChart>
            </ChartContainer>
          </CardContent>
          {avgDuration !== 0 && (
            <CardFooter className="justify-center">
              <p className="font-semibold text-lg text-center leading-none">
                Sur cette période, la durée d'utilisation est en moyenne de{' '}
                {formatSecondsLabel(avgDuration)}.
              </p>
            </CardFooter>
          )}
        </Card>
      )}
    </div>
  );
};
