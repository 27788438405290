import { AdminSignInRequest, SignInResponse } from '@repo/types/src/auth';
import { publicAxiosInstance } from '@/config/public-axios.config';

export const signIn = async (body: AdminSignInRequest) => {
  const response = await publicAxiosInstance.post<SignInResponse>(
    `/auth/admin/signin`,
    body,
  );
  return response.data;
};
