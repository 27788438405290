import { PointOfSales } from '@repo/types/src/point-of-sales';
import { Trash } from 'lucide-react';
import { toast } from 'sonner';
import { useState } from 'react';
import { useDeletePointOfSales } from '@/api/point-of-sales/point-of-sales.hooks';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

export const DeletePointOfSales = (pointOfSales: PointOfSales) => {
  const [open, setOpen] = useState(false);
  const mutation = useDeletePointOfSales();

  const handleDelete = () => {
    mutation.mutate(pointOfSales.id, {
      onSuccess: () => {
        setOpen(false);
        toast.success(
          `Le point de vente ${pointOfSales.name} a été supprimé avec succès!`,
          { duration: 3000 },
        );
      },
      onError: () => {
        toast.error(
          `Erreur lors de la suppression du point de vente ${pointOfSales.name}`,
          { duration: 3000 },
        );
      },
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Trash color="red" size={24} />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader></DialogHeader>
        <DialogTitle className="text-center">Supprimer</DialogTitle>
        <DialogDescription className="text-center">
          Êtes vous sûr de vouloir supprimer ce point de vente ?
        </DialogDescription>
        <DialogFooter className="flex gap-2">
          <DialogClose asChild>
            <Button variant="ghost">Annuler</Button>
          </DialogClose>
          <Button variant="destructive" onClick={handleDelete}>
            Supprimer
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
