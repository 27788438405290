import { Link, useLocation } from 'react-router-dom';
import { ShoppingCart, LayoutDashboard, LogOut, Droplet } from 'lucide-react';
import { ReactNode } from 'react';
import { cn } from '@/lib/utils';
import { Paths } from '@/routes/paths';
import { Separator } from '@/components/ui/separator';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/providers/auth.context';

export type NavItem = {
  title: string;
  href: string;
  icon: ReactNode;
};

const items: NavItem[] = [
  {
    title: 'Dashboard',
    href: Paths.Dashboard,
    icon: <LayoutDashboard className="w-9" />,
  },
  {
    title: 'Culottes menstruelles',
    href: Paths.Underwear,
    icon: <Droplet className="w-9" />,
  },
  {
    title: 'Points de vente',
    href: Paths.PointOfSales,
    icon: <ShoppingCart className="w-9" />,
  },
];
export const SidebarNav = () => {
  const { pathname } = useLocation();
  const { removeSession } = useAuth();

  return (
    <div className="flex flex-col h-screen w-full bg-zinc-900">
      <div className="flex flex-col items-start justify-start gap-0 mb-12 pl-4 pr-4 text-zinc-50 pt-8">
        <h4 className="mb-1 rounded-md px-2 py-1 text-xl font-medium">
          Mademoiselle Culotte
        </h4>
        <h4 className="mb-1 rounded-md px-2 py-1 text-lg font-medium">
          Espace Administrateur
        </h4>
        <Separator className="mt-4" />
      </div>
      <div className="flex w-full flex-grow flex-col items-start justify-start gap-9 mb-12 pl-4 pr-4 text-zinc-50">
        {items.map((item, index) => (
          <div key={index} className="w-full">
            <Link
              key={index}
              to={item.href}
              className={cn(
                'flex w-full items-center rounded-md p-2 hover:bg-zinc-600 font-medium text-zinc-50',
                {
                  'bg-zinc-600': pathname === item.href,
                },
              )}
            >
              <div className="flex items-center gap-1">
                {item.icon}
                <span>{item.title}</span>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <div className="pl-4 pr-4 mb-12 text-zinc-100">
        <Button
          variant={'ghost'}
          size={'default'}
          className="w-full justify-start items-center"
          onClick={() => removeSession()}
        >
          <div className="flex items-center gap-1">
            <LogOut />
            <p className="text-md">Se déconnecter</p>
          </div>
        </Button>
      </div>
    </div>
  );
};
