import { UnderwearCategory, UnderwearResponse } from '@repo/types';

export const groupUnderwearsByCategory = (underwears: UnderwearResponse[]) =>
  underwears.reduce(
    (acc, underwear) => {
      const { category } = underwear;
      if (Object.keys(acc).includes(category)) acc[category].push(underwear);
      else acc[category] = [underwear];
      return acc;
    },
    {} as Record<UnderwearCategory, UnderwearResponse[]>,
  );
