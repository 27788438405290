import { Pie, PieChart } from 'recharts';
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
  type ChartConfig,
} from '@/components/ui/chart';
import { useGetContraceptionDistribution } from '@/api/dashboard/dashboard.hooks';
import { ChartSkeleton } from '@/components/dashboard/chart-skeleton';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';

const chartConfig = {
  users: {
    label: 'Utilisatrices',
  },
  PILL: {
    label: 'Pilule',
    color: 'hsl(var(--chart-2))',
  },
  HORMONAL_IUD: {
    label: 'DIU hormonal',
    color: 'hsl(var(--chart-4))',
  },
  COPPER_IUD: {
    label: 'DIU cuivre',
    color: 'hsl(var(--chart-3))',
  },
  IMPLANT: {
    label: 'Implant',
    color: 'hsl(var(--chart-1))',
  },
  INJECTION: {
    label: 'Injection',
    color: 'hsl(var(--chart-5))',
  },
  PATCH: {
    label: 'Patch',
    color: 'hsl(var(--chart-6))',
  },
  VAGINAL_RING: {
    label: 'Anneau vaginal',
    color: 'hsl(var(--chart-7))',
  },
  OTHER: {
    label: 'Autre',
    color: 'hsl(var(--chart-8))',
  },
  NO_CONTRACEPTION: {
    label: 'Pas de contraception',
    color: 'hsl(var(--chart-9))',
  },
} satisfies ChartConfig;

export const ContraceptionPieChart = () => {
  const { contraceptionDistribution, isError, isLoading } =
    useGetContraceptionDistribution();

  const contraceptionDistributionWithColors = contraceptionDistribution?.map(
    (contraception) => {
      return {
        ...contraception,
        fill: `var(--color-${contraception.contraception})`,
      };
    },
  );

  const usersCount = contraceptionDistribution?.reduce(
    (acc, curr) => acc + curr.users,
    0,
  );

  return (
    <div className="w-full h-full">
      {isLoading || isError ? (
        <ChartSkeleton />
      ) : (
        <Card>
          <CardHeader>
            <CardTitle>
              Répartition des utilisatrices par moyens de contraception
            </CardTitle>
          </CardHeader>
          <CardContent>
            <ChartContainer
              config={chartConfig}
              className="mx-auto aspect-square"
            >
              <PieChart
                margin={{
                  top: 20,
                }}
              >
                <ChartTooltip
                  content={
                    <ChartTooltipContent nameKey="contraception" hideLabel />
                  }
                />
                <Pie
                  data={contraceptionDistributionWithColors}
                  dataKey="users"
                  labelLine={false}
                  label={({ payload, ...props }) => {
                    return (
                      <text
                        cx={props.cx}
                        cy={props.cy}
                        x={props.x}
                        y={props.y}
                        textAnchor={props.textAnchor}
                        dominantBaseline={props.dominantBaseline}
                        fill="hsla(var(--foreground))"
                      >
                        {usersCount && payload.users
                          ? `${Math.round((payload.users / usersCount) * 100)}%`
                          : ''}
                      </text>
                    );
                  }}
                  nameKey="contraception"
                />
                <ChartLegend
                  content={<ChartLegendContent nameKey="contraception" />}
                  className="flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center"
                />
              </PieChart>
            </ChartContainer>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
