import { FlowIntensityType } from "./flow";

export enum UnderwearCategory {
  PANTY = "PANTY",
  BREATHABLE_PANTY = "BREATHABLE_PANTY",
  PANTY_TEENAGER = "PANTY_TEENAGER",
  BOXER = "BOXER",
  TANGA = "TANGA",
}
export type UnderwearCategoryType = keyof typeof UnderwearCategory;

export enum UnderwearCategoryTranslationFR {
  PANTY = 'Culotte menstruelle',
  BREATHABLE_PANTY = 'Culotte menstruelle respirante',
  PANTY_TEENAGER = 'Culotte menstruelle ados',
  BOXER = 'Boxer menstruel',
  TANGA = 'Tanga menstruel',
}

export interface UnderwearRequest {
  name: string;
  category: UnderwearCategoryType;
  file: File;
  sortOrder: number;
}

export interface UnderwearResponse {
  id: number;
  name: string;
  category: UnderwearCategoryType;
  image: string;
  createdAt: Date;
  sortOrder: number;
}

export interface UnderwearLogRequest {
  underwear: string;
  startDate: Date;
  flowIntensity: FlowIntensityType;
}

export interface UnderwearLogResponse {
  id: number;
  underwearId: number;
  startDate: Date;
  recommendedEndDate: Date;
  maxEndDate: Date;
  actualEndDate: Date | null;
  isActive: boolean;
  reminder: Date | null;
  createdAt: Date;
  updatedAt: Date;
}
