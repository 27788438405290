import { PointOfSalesRequest } from '@repo/types/src/point-of-sales';
import { CirclePlus } from 'lucide-react';
import { toast } from 'sonner';
import { useState } from 'react';
import { useCreatePointOfSales } from '@/api/point-of-sales/point-of-sales.hooks';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  useZodForm,
} from '@/lib/form';
import { schema } from '@/schemas/point-of-sales.schema';
import { Input } from '@/components/ui/input';

export const AddPointOfSales = () => {
  const mutation = useCreatePointOfSales();
  const [open, setOpen] = useState(false);

  const form = useZodForm({
    schema,
    defaultValues: { name: '', address: '', zip: '', city: '' },
  });

  const handleCreate = (payload: PointOfSalesRequest) => {
    mutation.mutate(payload, {
      onSuccess: (data) => {
        setOpen(false);
        toast.success(
          `Le point de vente ${data.name} a été créé avec succès!`,
          { duration: 3000 },
        );
      },
      onError: () => {
        toast.error(
          `Erreur lors de la création du point de vente ${payload.name}`,
          { duration: 3000 },
        );
      },
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button>
          <div className="flex flex-row gap-2 items-center">
            <CirclePlus size={24} />
            <span>Ajouter un point de vente</span>
          </div>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <Form
          form={form}
          onSubmit={handleCreate}
          className="w-full flex flex-col space-y-4"
        >
          <DialogHeader></DialogHeader>
          <DialogTitle className="text-center">Ajouter</DialogTitle>
          <DialogDescription className="text-center">
            Ajouter un point de vente
          </DialogDescription>

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    type="text"
                    placeholder="Nom du point de vente"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input type="text" placeholder="Adresse" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="zip"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input type="text" placeholder="Code postal" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input type="text" placeholder="Ville" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <DialogFooter className="flex gap-2">
            <DialogClose asChild>
              <Button variant="ghost">Annuler</Button>
            </DialogClose>
            <Button variant="default" type="submit">
              Ajouter
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
