import { AgeBarChart } from '@/components/dashboard/user/age-bar-chart';
import { FlowPieChart } from '@/components/dashboard/user/flow-pie-chart';
import { ContraceptionPieChart } from '@/components/dashboard/user/contraception-pie-chart';
import { DashboardLayout } from '@/layouts/DashboardLayout';
import { DiseasePieChart } from '@/components/dashboard/user/disease-pie-chart';
import { SymptomBarChart } from '@/components/dashboard/user/symptom-bar-chart';

export const UserDashboard = () => {
  return (
    <DashboardLayout>
      <AgeBarChart />
      <FlowPieChart />
      <ContraceptionPieChart />
      <DiseasePieChart />
      <SymptomBarChart />
    </DashboardLayout>
  );
};
