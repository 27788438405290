import { LoginForm } from '@/components/auth/LoginForm';

export const LoginPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-zinc-900">
      <div className="flex flex-col items-center justify-center w-full max-w-md px-12 py-8 mt-10 space-y-6 bg-white rounded-lg shadow-md">
        <div className="flex flex-col items-center justify-center gap-4">
          <h1 className="text-3xl font-bold text-center">
            Mademoiselle Culotte
          </h1>
          <h2 className="text-center text-2xl">Espace administrateur</h2>
        </div>
        <p className="text-center text-zinc-500">
          Connectez-vous pour accéder à votre espace administrateur
        </p>
        <LoginForm />
      </div>
    </div>
  );
};
