import { UnderwearResponse } from '@repo/types/src/underwear';
import dayjs from 'dayjs';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { DeleteUnderwear } from '@/components/underwear/DeleteUnderwear';
import { getS3Url } from '@/config/s3.config';

export const UnderwearCard = (underwear: UnderwearResponse) => {
  const { image, name, createdAt, sortOrder } = underwear;

  return (
    <Card className="overflow-hidden w-60 h-fit">
      <CardContent className="p-0 aspect-[1/1] w-full">
        <img
          src={getS3Url(image)}
          alt="Underwear"
          className="object-cover w-full h-full"
        />
      </CardContent>
      <CardFooter className="flex items-center justify-between w-full pt-2 pb-2">
        <div className="flex items-start gap-0 flex-col">
          <p className="text-lg font-bold">{name}</p>
          <p className="flex items-start flex-row text-zinc-500 text-sm gap-2">
            Ordre de tri : {sortOrder}
          </p>
          <p className="flex items-start flex-row text-zinc-500 text-sm">
            Ajoutée le {dayjs(createdAt).format('DD MMM YYYY')}
          </p>
        </div>
        <DeleteUnderwear {...underwear} />
      </CardFooter>
    </Card>
  );
};
