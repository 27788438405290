import { useGetPointOfSales } from '@/api/point-of-sales/point-of-sales.hooks';
import { ErrorScreen } from '@/components/ui/error-screen';
import { columns } from '@/components/point-of-sales/columns';
import { DataTable } from '@/components/point-of-sales/data-table';
import { LoadingScreen } from '@/components/ui/loading-screen';
import { AppPageLayout } from '@/layouts/AppPageLayout';
import { AddPointOfSales } from '@/components/point-of-sales/AddPointOfSales';

export const PointOfSalesPage = () => {
  const { pointsOfSales, isError, isLoading } = useGetPointOfSales();

  if (isLoading) return <LoadingScreen />;
  if (isError || !pointsOfSales) return <ErrorScreen />;
  return (
    <AppPageLayout
      title="Points de vente"
      description="Gestion des points de vente"
    >
      <div className="flex flex-col gap-4 h-full ">
        <div className="flex justify-end">
          <AddPointOfSales />
        </div>
        <DataTable columns={columns} data={pointsOfSales} />
      </div>
    </AppPageLayout>
  );
};
