import { z } from 'zod';

const requiredMessage = 'Champ requis';

export const schema = z.object({
  name: z.string().trim().min(1, requiredMessage),
  address: z.string().trim().min(1, requiredMessage),
  zip: z
    .string()
    .trim()
    .min(1, { message: requiredMessage })
    .refine((value) => /^\d{5}$/.test(value), {
      message: 'Le code postal doit être composé de 5 chiffres.',
    }),
  city: z.string().trim().min(1, requiredMessage),
});

export type PointOfSalesSchemaType = z.infer<typeof schema>;
