import { useMutation, useQuery } from '@tanstack/react-query';
import {
  PointOfSalesRequest,
  PointOfSales,
} from '@repo/types/src/point-of-sales';
import { pointOfSalesQueryKeys } from '@/api/point-of-sales/point-of-sales.query-keys';
import {
  createPointOfSales,
  deletePointOfSales,
  getAllPointOfSales,
} from '@/api/point-of-sales/point-of-sales.queries';
import { queryClient } from '@/config/query-client.config';

export const useGetPointOfSales = () => {
  const {
    data: pointsOfSales,
    isError,
    isLoading,
  } = useQuery({
    queryKey: pointOfSalesQueryKeys.all,
    queryFn: getAllPointOfSales,
  });

  return { pointsOfSales, isError, isLoading };
};

export const useCreatePointOfSales = () => {
  const mutation = useMutation({
    mutationFn: (payload: PointOfSalesRequest) => createPointOfSales(payload),
    onSuccess: (data) => {
      queryClient.setQueryData<PointOfSales[]>(
        pointOfSalesQueryKeys.all,
        (oldData) => (oldData ? [...oldData, data] : [data]),
      );
    },
  });

  return mutation;
};

export const useDeletePointOfSales = () => {
  const mutation = useMutation({
    mutationFn: (id: number) => deletePointOfSales(id),
    onSuccess: (data) => {
      queryClient.setQueryData<PointOfSales[]>(
        pointOfSalesQueryKeys.all,
        (oldData) =>
          oldData?.filter((pointOfSale) => pointOfSale.id !== data.id),
      );
    },
  });

  return mutation;
};
