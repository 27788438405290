import { DashboardLayout } from '@/layouts/DashboardLayout';
import { FeatureUsageBarChart } from '@/components/dashboard/analytics/feature-usage-bar-chart';
import { SessionDurationBarChart } from '@/components/dashboard/analytics/session-duration-bar-chart';
import { UserRetentionBarChart } from './user-retention-bar-chart';

export const AnalyticsDashboard = () => {
  return (
    <DashboardLayout>
      <UserRetentionBarChart />
      <FeatureUsageBarChart />
      <SessionDurationBarChart />
    </DashboardLayout>
  );
};
