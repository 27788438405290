import { createContext, useContext } from 'react';

export type AuthContextData = {
  accessToken: string | null;
  createSession: (accessToken: string) => void;
  removeSession: () => void;
};

export const AuthContext = createContext({} as AuthContextData);

export function useAuth() {
  return useContext(AuthContext);
}
