import {
  UnderwearCategoryType,
  UnderwearResponse,
  UnderwearCategoryTranslationFR,
} from '@repo/types/src/underwear';
import { UnderwearCard } from '@/components/underwear/UnderwearCard';
import { groupUnderwearsByCategory } from '@/components/underwear/utils';

type CategoryListProps = {
  category: UnderwearCategoryType;
  underwears: UnderwearResponse[];
};
type ListProps = {
  underwears: UnderwearResponse[];
};

export const UnderwearCategoryList = ({
  category,
  underwears,
}: CategoryListProps) => {
  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-xl font-bold">
        {UnderwearCategoryTranslationFR[category]}
      </h1>
      <div className="flex flex-wrap gap-4">
        {underwears.map((underwear) => (
          <UnderwearCard key={underwear.id} {...underwear} />
        ))}
      </div>
    </div>
  );
};

export const UnderwearList = ({ underwears }: ListProps) => {
  const sortedUnderwears = groupUnderwearsByCategory(underwears);

  return (
    <div className="flex flex-col gap-12 h-full overflow-auto pb-12">
      {Object.entries(sortedUnderwears).map(([category, underwearList]) => (
        <UnderwearCategoryList
          key={category}
          category={category as UnderwearCategoryType}
          underwears={underwearList}
        />
      ))}
    </div>
  );
};
