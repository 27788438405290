import axios, { AxiosError, AxiosInstance } from 'axios';
import { SignInResponse } from '@repo/types/src/auth';

export const API_URL = import.meta.env.VITE_API_URL;
export const REFRESH_TOKEN_KEY = 'refresh-token';
export const ACCESS_TOKEN_KEY = 'access-token';

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (
    error: AxiosError<{ error: string; message: string; statusCode: number }>,
  ) => {
    // eslint-disable-next-line no-underscore-dangle
    if (error.response?.status === 401 && !error.request._retry) {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
      try {
        const response = await axiosInstance.post<SignInResponse>(
          'auth/admin/refresh-token',
          {},
          { headers: { Authorization: `Bearer ${refreshToken}` } },
        );

        if (response.status === 201) {
          localStorage.setItem(ACCESS_TOKEN_KEY, response.data.access_token);
          return await axiosInstance(error.request);
        }
      } catch {
        /* empty */
      }
    }

    if (error.response && error.response.data && error.response.data.message) {
      return Promise.reject(new Error(error.response.data.message));
    }
    if (error.config && error.config.url) {
      const route = error.config.url.split('/').pop();
      const errorMessage = `Failed on ${route} request`;
      return Promise.reject(new Error(errorMessage));
    }
    return Promise.reject(error);
  },
);
