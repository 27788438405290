import { useMutation } from '@tanstack/react-query';
import { AdminSignInRequest, SignInResponse } from '@repo/types/src/auth';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/config/axios.config';
import { signIn } from '@/api/auth/auth.queries';
import { useAuth } from '@/providers/auth.context';

export const useSignIn = () => {
  const { createSession } = useAuth();

  const mutation = useMutation({
    mutationFn: (payload: AdminSignInRequest) => signIn(payload),
    onSuccess: (response: SignInResponse) => {
      const { access_token: accessToken, refresh_token: refreshToken } =
        response;
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
      createSession(accessToken);
    },
  });

  return mutation;
};
