import { CartesianGrid, XAxis, Area, AreaChart, YAxis } from 'recharts';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import {
  FlowIntensityType,
  GynecologicalDiseaseType,
  UnderwearResponse,
} from '@repo/types';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from '@/components/ui/card';
import {
  FlowIntensityFr,
  GynecologicalDiseaseFr,
} from '@/components/dashboard/utils';
import { useGetUnderwears } from '@/api/underwear/underwear.hooks';
import { Button } from '@/components/ui/button';
import { useGetUnderwearWearingTimeDistribution } from '@/api/dashboard/dashboard.hooks';
import { ChartSkeleton } from '@/components/dashboard/chart-skeleton';

const chartConfig = {
  normal: {
    label: 'Normal',
    color: 'hsl(var(--chart-2))',
  },
  custom: {
    label: 'Avec filtres',
    color: 'hsl(var(--chart-1))',
  },
} satisfies ChartConfig;

export const UnderwearAreaChart = () => {
  const [selectedFlow, setSelectedFlow] = useState<FlowIntensityType>();
  const [selectedDisease, setSelectedDisease] =
    useState<GynecologicalDiseaseType>();
  const [selectedUnderwear, setSelectedUnderwear] =
    useState<UnderwearResponse>();

  const {
    underwears,
    isError: isUnderwearError,
    isLoading: isUnderwearLoading,
  } = useGetUnderwears();
  const {
    underwearWearingTimeDistribution: normalData,
    isError: isNormalDataError,
    isLoading: isNormalDataLoading,
  } = useGetUnderwearWearingTimeDistribution();
  const {
    underwearWearingTimeDistribution: filteredData,
    isError: isFilteredDataError,
    isLoading: isFilteredDataLoading,
  } = useGetUnderwearWearingTimeDistribution(
    selectedFlow,
    selectedDisease,
    selectedUnderwear?.id,
  );

  const mergedData = useMemo(() => {
    if (!normalData || !filteredData) return [];
    const allDays = normalData
      .concat(filteredData)
      .map((item) => item.dayInCycle);
    const uniqueDays = Array.from(new Set(allDays));

    const result: {
      day: string;
      normal: number;
      custom: number;
    }[] = [];

    uniqueDays.forEach((day) => {
      const normalDataDay = normalData.find((item) => item.dayInCycle === day);
      const filteredDataDay = filteredData.find(
        (item) => item.dayInCycle === day,
      );

      result.push({
        day: `Jour ${day}`,
        normal: normalDataDay?.wearingTimeInMinutes ?? 0,
        custom: filteredDataDay?.wearingTimeInMinutes ?? 0,
      });
    });

    return result;
  }, [normalData, filteredData]);

  const isLoading =
    isUnderwearLoading || isNormalDataLoading || isFilteredDataLoading;
  const isError = isUnderwearError || isNormalDataError || isFilteredDataError;
  if (isLoading || isError || !underwears || !normalData || !filteredData)
    return (
      <div className="w-full h-full my-6">
        <ChartSkeleton />
      </div>
    );

  const formatMinutesToHHmm = (minutes: number) => {
    const dur = dayjs.duration(minutes, 'minutes');
    return dur.format('HH:mm');
  };

  const handleOnFlowChange = (flow: FlowIntensityType) => {
    setSelectedFlow(flow);
  };

  const handleOnDiseaseChange = (disease: GynecologicalDiseaseType) => {
    setSelectedDisease(disease);
  };

  const handleOnUnderwearChange = (underwearName: string) => {
    const underwear = underwears.find((u) => u.name === underwearName);
    setSelectedUnderwear(underwear);
  };

  const handleOnClearFilters = () => {
    setSelectedFlow(undefined);
    setSelectedDisease(undefined);
    setSelectedUnderwear(undefined);
  };

  return (
    <Card className="w-full my-6">
      <CardHeader>
        <CardTitle>Durée de port moyenne par cycle</CardTitle>
        <CardDescription>
          La courbe 'Normale' correspond à la durée de port moyenne pour
          l'ensemble des utilisatrices. La courbe 'Avec filtres' correspond à la
          durée moyenne de port des utilisatrices avec les filtres actifs.
        </CardDescription>
      </CardHeader>
      <CardContent className="flex h-full w-full flex-col gap-10 items-center">
        <div className="w-full flex flex-col xl:flex-row gap-4 items-end">
          <div className="w-full flex flex-col xl:flex-row gap-4 items-end">
            <Select
              value={selectedUnderwear?.name}
              onValueChange={(underwearName) =>
                handleOnUnderwearChange(underwearName)
              }
            >
              <SelectTrigger className="w-[250px]">
                <SelectValue placeholder="Sélectionner une culotte" />
              </SelectTrigger>
              <SelectContent>
                {underwears.map((underwear) => (
                  <SelectItem key={underwear.id} value={underwear.name}>
                    {underwear.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select value={selectedFlow} onValueChange={handleOnFlowChange}>
              <SelectTrigger className="w-[250px]">
                <SelectValue placeholder="Sélectionner un flux" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(FlowIntensityFr).map(([key, value]) => (
                  <SelectItem key={key} value={key}>
                    {value}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select
              value={selectedDisease}
              onValueChange={handleOnDiseaseChange}
            >
              <SelectTrigger className="w-[250px]">
                <SelectValue placeholder="Sélectionner une maladie" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(GynecologicalDiseaseFr).map(([key, value]) => (
                  <SelectItem key={key} value={key}>
                    {value}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <Button
            variant={'destructive'}
            className="w-[250px]"
            onClick={handleOnClearFilters}
          >
            Nettoyer les filtres
          </Button>
        </div>
        <ChartContainer config={chartConfig} className="w-[75%]">
          <AreaChart
            accessibilityLayer
            data={mergedData}
            margin={{
              left: 12,
              right: 12,
              bottom: 12,
            }}
          >
            <ChartTooltip
              content={
                <ChartTooltipContent
                  hideLabel
                  valueFormatter={(value) =>
                    formatMinutesToHHmm(value as number)
                  }
                />
              }
            />
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="day"
              tickLine={false}
              axisLine={false}
              tickMargin={10}
            />
            <YAxis
              dataKey="normal"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              allowDecimals={false}
              tickFormatter={formatMinutesToHHmm}
            />

            <Area
              dataKey="normal"
              type="linear"
              fill="var(--color-normal)"
              fillOpacity={0.4}
              stroke="var(--color-normal)"
              stackId="a"
            />
            <Area
              dataKey="custom"
              type="linear"
              fill="var(--color-custom)"
              fillOpacity={0.4}
              stroke="var(--color-custom)"
              stackId="b"
            />
            <ChartLegend content={<ChartLegendContent />} />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};
