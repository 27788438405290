import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { useSignIn } from '@/api/auth/auth.hooks';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { PasswordInput } from '@/components/ui/password-input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  useZodForm,
} from '@/lib/form';
import { LoginSchemaType, schema } from '@/schemas/login.schema';
import { Paths } from '@/routes/paths';

export const LoginForm = () => {
  const navigate = useNavigate();

  const mutation = useSignIn();
  const form = useZodForm({
    schema,
    defaultValues: { username: '', password: '' },
  });

  const onSubmit = (data: LoginSchemaType) =>
    mutation.mutate(data, {
      onSuccess: () => navigate(Paths.Dashboard),
      onError: () =>
        toast.error('Erreur lors de la connexion', { duration: 3000 }),
    });

  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      className="w-full flex flex-col space-y-4"
    >
      <FormField
        control={form.control}
        name="username"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input type="text" placeholder="Nom d'utilisateur" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="password"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <PasswordInput placeholder="Mot de passe" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <Button type="submit">Connexion</Button>
    </Form>
  );
};
