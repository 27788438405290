import { Outlet } from 'react-router-dom';
import { SidebarNav } from '@/layouts/SidebarNav';

export const AppLayout = () => {
  return (
    <div className="flex flex-row h-screen">
      <div className="w-1/4 h-full">
        <SidebarNav />
      </div>
      <div className="flex flex-col w-full h-full">
        <Outlet />
      </div>
    </div>
  );
};
