import { ErrorScreen } from '@/components/ui/error-screen';
import { LoadingScreen } from '@/components/ui/loading-screen';
import { AppPageLayout } from '@/layouts/AppPageLayout';
import { useGetUnderwears } from '@/api/underwear/underwear.hooks';
import { UnderwearList } from '@/components/underwear/UnderwearList';
import { AddUnderwear } from '@/components/underwear/AddUnderwear';

export const UnderwearPage = () => {
  const { underwears, isError, isLoading } = useGetUnderwears();

  if (isLoading) return <LoadingScreen />;
  if (isError || !underwears) return <ErrorScreen />;

  return (
    <AppPageLayout
      title="Culottes menstruelles"
      description="Gestion des culottes menstruelles"
    >
      <div className="flex flex-col gap-4 h-full">
        <div className="flex justify-end">
          <AddUnderwear />
        </div>
        <UnderwearList underwears={underwears} />
      </div>
    </AppPageLayout>
  );
};
