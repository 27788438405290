import { CirclePlus } from 'lucide-react';
import { toast } from 'sonner';
import { useState } from 'react';
import {
  UnderwearCategory,
  UnderwearCategoryTranslationFR,
  UnderwearRequest,
} from '@repo/types/src/underwear';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useZodForm,
} from '@/lib/form';
import { schema } from '@/schemas/underwear.schema';
import { Input } from '@/components/ui/input';
import { useCreateUnderwear } from '@/api/underwear/underwear.hooks';

export const AddUnderwear = () => {
  const mutation = useCreateUnderwear();
  const [open, setOpen] = useState(false);

  const form = useZodForm({
    schema,
    defaultValues: { name: '', sortOrder: 0, category: 'PANTY' },
  });

  const handleCreate = (payload: UnderwearRequest) => {
    mutation.mutate(payload, {
      onSuccess: (data) => {
        setOpen(false);
        toast.success(`La culotte ${data.name} a été créée avec succès!`, {
          duration: 3000,
        });
      },
      onError: () => {
        toast.error(
          `Erreur lors de la création de la culotte ${payload.name}`,
          { duration: 3000 },
        );
      },
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button>
          <div className="flex flex-row gap-2 items-center">
            <CirclePlus size={24} />
            <span>Ajouter une culotte</span>
          </div>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <Form
          form={form}
          onSubmit={handleCreate}
          className="w-full flex flex-col space-y-4"
        >
          <DialogHeader></DialogHeader>
          <DialogTitle className="text-center">Ajouter</DialogTitle>
          <DialogDescription className="text-center">
            Ajouter une culotte menstruelle
          </DialogDescription>

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input type="text" placeholder="Nom du modèle" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="category"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Catégorie</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Choisissez une catégorie" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.entries(UnderwearCategory).map(([key, value]) => (
                      <SelectItem key={key} value={value}>
                        {UnderwearCategoryTranslationFR[value]}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="sortOrder"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Ordre d'apparition</FormLabel>
                <FormDescription>
                  Définition de l'ordre d'apparition des culottes dans
                  l'application
                </FormDescription>
                <FormControl>
                  <Input
                    type="number"
                    placeholder="Ordre d'apparition"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="file"
            render={({ field: { value, onChange, ...fieldProps } }) => {
              return (
                <FormItem>
                  <FormLabel>Image</FormLabel>
                  <FormDescription>
                    Format recommandé : Image PNG (823 x 823 pixels)
                  </FormDescription>
                  <FormControl>
                    <Input
                      type="file"
                      placeholder="Fichier"
                      {...fieldProps}
                      accept="image/png"
                      onChange={(event) =>
                        onChange(event.target.files && event.target.files[0])
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <DialogFooter className="flex gap-2">
            <DialogClose asChild>
              <Button variant="ghost">Annuler</Button>
            </DialogClose>
            <Button variant="default" type="submit">
              Ajouter
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
