import {
  ActiveUsersByTimeScale,
  AgeDistribution,
  ContraceptionDistribution,
  CyclePhaseType,
  FeatureUsageDistribution,
  FlowDistribution,
  GynecologicalDiseaseDistribution,
  SessionDurationByTimeScale,
  SymptomDistribution,
  TimeScale,
  UnderwearWearingTimeDistribution,
  FlowIntensityType,
  GynecologicalDiseaseType,
} from '@repo/types';
import { axiosInstance } from '@/config/axios.config';

export const getAgeDistribution = async () => {
  const response = await axiosInstance.get<AgeDistribution[]>(
    `/dashboard/age-distribution`,
  );
  return response.data;
};

export const getFlowDistribution = async () => {
  const response = await axiosInstance.get<FlowDistribution[]>(
    `/dashboard/flow-distribution`,
  );
  return response.data;
};

export const getContraceptionDistribution = async () => {
  const response = await axiosInstance.get<ContraceptionDistribution[]>(
    `/dashboard/contraception-distribution`,
  );
  return response.data;
};

export const getDiseaseDistribution = async () => {
  const response = await axiosInstance.get<{
    distribution: GynecologicalDiseaseDistribution[];
    usersCount: number;
  }>(`/dashboard/disease-distribution`);
  return response.data;
};

export const getSymptomDistribution = async (cyclePhase?: CyclePhaseType) => {
  const response = await axiosInstance.get<{
    distribution: SymptomDistribution[];
    usersCount: number;
  }>(`/dashboard/symptom-distribution`, {
    params: { 'cycle-phase': cyclePhase },
  });
  return response.data;
};

export const getUnderwearWearingTimeDistribution = async (
  flow?: FlowIntensityType,
  disease?: GynecologicalDiseaseType,
  underwearId?: number,
) => {
  const response = await axiosInstance.get<UnderwearWearingTimeDistribution[]>(
    `/dashboard/underwear-wearing-time-distribution`,
    {
      params: {
        flow,
        disease,
        underwearId,
      },
    },
  );
  return response.data;
};

export const getFeatureUsageDistribution = async () => {
  const response = await axiosInstance.get<FeatureUsageDistribution[]>(
    `/dashboard/feature-usage-distribution`,
  );
  return response.data;
};

export const getActiveUsersByTimeScale = async (timeScale?: TimeScale) => {
  const response = await axiosInstance.get<{
    distribution: ActiveUsersByTimeScale[];
    usersCount: number;
  }>(`/dashboard/active-users`, { params: { timeScale } });
  return response.data;
};

export const getAvgSessionDurationByTimeScale = async (
  timeScale?: TimeScale,
) => {
  const response = await axiosInstance.get<SessionDurationByTimeScale[]>(
    `/dashboard/avg-session-duration`,
    { params: { timeScale } },
  );
  return response.data;
};
