import { Children } from 'react';

type Props = {
  children: React.ReactNode | null | undefined;
};
export const DashboardLayout = ({ children }: Props) => {
  const isOdd = Children.count(children) % 2 !== 0;

  return (
    <div
      className={`grid flex-1 gap-4 py-6 mb-6 ${!children || !Array.isArray(children) || children.length === 1 ? 'grid-cols-1' : 'md:grid-cols-1 xl:grid-cols-2'}`}
    >
      {Children.map(children, (child, index) =>
        isOdd && Children.count(children) - 1 === index ? (
          <div className="col-span-full">{child}</div>
        ) : (
          child
        ),
      )}
    </div>
  );
};
