import { UnderwearRequest, UnderwearResponse } from '@repo/types/src/underwear';
import { axiosInstance } from '@/config/axios.config';

export const getAllUnderwears = async () => {
  const response =
    await axiosInstance.get<UnderwearResponse[]>(`/underwear/admin`);
  return response.data;
};

export const createUnderwear = async (payload: UnderwearRequest) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('name', payload.name);
  formData.append('category', payload.category);
  formData.append('sortOrder', payload.sortOrder.toString());

  const response = await axiosInstance.post<UnderwearResponse>(
    `/underwear/admin`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const deleteUnderwear = async (id: number) => {
  const response = await axiosInstance.delete<UnderwearResponse>(
    `/underwear/admin/${id}`,
  );
  return response.data;
};
