import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
} from 'recharts';
import {
  ChartContainer,
  ChartTooltipContent,
  ChartTooltip,
  type ChartConfig,
} from '@/components/ui/chart';
import { useGetFeatureUsageDistribution } from '@/api/dashboard/dashboard.hooks';
import { ChartSkeleton } from '@/components/dashboard/chart-skeleton';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';

const chartConfig = {
  sessionUsageRatio: {
    label: "% d'utilisation par session",
  },
  label: {
    color: 'white',
  },
  CALENDAR: {
    label: 'Calendrier',
    color: 'hsl(var(--chart-1))',
  },
  UNDERWEAR: {
    label: 'Culotte menstruelle',
    color: 'hsl(var(--chart-6))',
  },
  PROFILE: {
    label: 'Profil',
    color: 'hsl(var(--chart-3))',
  },
  SHOP: {
    label: 'Boutique',
    color: 'hsl(var(--chart-4))',
  },
  BLOG: {
    label: 'Blog',
    color: 'hsl(var(--chart-5))',
  },
} satisfies ChartConfig;

export const FeatureUsageBarChart = () => {
  const { distribution, isError, isLoading } = useGetFeatureUsageDistribution();

  const formattedDistribution = distribution
    ?.map(({ feature, sessionUsageRatio }) => ({
      feature,
      sessionUsageRatio,
      label: chartConfig[feature]?.label,
      fill: chartConfig[feature]?.color,
    }))
    .sort((a, b) => b.sessionUsageRatio - a.sessionUsageRatio);

  const formatPercentage = (value: number) => `${value.toFixed(0)} %`;

  return (
    <div className="w-full h-full">
      {isLoading || isError ? (
        <ChartSkeleton />
      ) : (
        <Card className="h-full">
          <CardHeader>
            <CardTitle>Adoption des fonctionnalités</CardTitle>
            <CardDescription>
              Moyenne sur 6 mois des fonctionnalités (onglets de l'application)
              utilisées par session utilisatrice.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <ChartContainer config={chartConfig} className="max-h-[500px]">
              <BarChart
                accessibilityLayer
                data={formattedDistribution}
                layout="vertical"
                margin={{
                  right: 16,
                  top: 16,
                  bottom: 18,
                }}
                maxBarSize={50}
              >
                <CartesianGrid horizontal={false} />
                <XAxis
                  type="number"
                  dataKey="sessionUsageRatio"
                  tickLine={false}
                  axisLine={false}
                  tick={false}
                  label={{
                    value: "% d'utilisation des onglets par session",
                    position: 'insideBottom',
                    dy: 20,
                  }}
                />
                <YAxis
                  dataKey="feature"
                  type="category"
                  tickLine={false}
                  tickMargin={10}
                  axisLine={false}
                  hide
                  interval={0}
                />
                <ChartTooltip
                  cursor={false}
                  content={
                    <ChartTooltipContent
                      indicator="line"
                      valueFormatter={(value) =>
                        formatPercentage(value as number)
                      }
                    />
                  }
                />

                <Bar dataKey="sessionUsageRatio" radius={4}>
                  <LabelList
                    dataKey="label"
                    position="insideLeft"
                    offset={8}
                    className="fill-[--color-label]"
                    fontSize={12}
                  />
                  <LabelList
                    dataKey="sessionUsageRatio"
                    position="right"
                    offset={-60}
                    className="fill-[--color-label]"
                    fontSize={12}
                    formatter={formatPercentage}
                  />
                </Bar>
              </BarChart>
            </ChartContainer>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
