import {
  PointOfSales,
  PointOfSalesRequest,
} from '@repo/types/src/point-of-sales';
import { axiosInstance } from '@/config/axios.config';

export const getAllPointOfSales = async () => {
  const response = await axiosInstance.get<PointOfSales[]>(
    `/admin/point-of-sales`,
  );
  return response.data;
};

export const createPointOfSales = async (payload: PointOfSalesRequest) => {
  const response = await axiosInstance.post<PointOfSales>(
    `/admin/point-of-sales`,
    payload,
  );
  return response.data;
};

export const deletePointOfSales = async (id: number) => {
  const response = await axiosInstance.delete<PointOfSales>(
    `/admin/point-of-sales/${id}`,
  );
  return response.data;
};
