import { PointOfSales } from '@repo/types/src/point-of-sales';
import { ColumnDef } from '@tanstack/react-table';
import { DeletePointOfSales } from './DeletePointOfSales';

export const columns: ColumnDef<PointOfSales>[] = [
  {
    accessorKey: 'name',
    header: 'Nom',
  },
  {
    accessorKey: 'address',
    header: 'Adresse',
  },
  {
    accessorKey: 'city',
    header: 'Ville',
  },
  {
    accessorKey: 'zip',
    header: 'Code postal',
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      const pointOfSales = row.original;
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <DeletePointOfSales {...pointOfSales} />
        </div>
      );
    },
  },
];
