import {
  FlowIntensity,
  GynecologicalDisease,
  CyclePhaseType,
  TimeScale,
} from '@repo/types';

export enum DashboardTabs {
  user = 'Utilisatrices',
  underwear = 'Culottes',
  app_perf = "Performances de l'application",
}
export type DashboardTabsType = keyof typeof DashboardTabs;

type EnumKeys<T> = { [K in keyof T]: string };

export const FlowIntensityFr: EnumKeys<typeof FlowIntensity> = {
  LIGHT: 'Léger',
  MODERATE: 'Modéré',
  HEAVY: 'Abondant',
  HEMORRHAGIC: 'Hémorragique',
};

export const GynecologicalDiseaseFr: EnumKeys<typeof GynecologicalDisease> = {
  ENDOMETRIOSIS: 'Endométriose',
  UTERINE_FIBROIDS: 'Fibromes utérins',
  COAGULATION_DISORDER: 'Troubles de la coagulation',
  SOPK: 'SOPK',
  THROMBOCYTOPENIA: 'Thrombocytopénie',
  MEDICATION: 'Médications',
  OTHER: 'Autre',
};

export const CyclePhaseFr: { [key in CyclePhaseType]: string } = {
  'during-periods': 'Pendant les règles',
  'outside-periods': 'En dehors des règles',
  'all-time': 'Sur tout le cycle',
};

export const TimeScaleFr: { [key in TimeScale]: string } = {
  week: 'Semaine',
  month: 'Mois',
  year: 'Année',
};
