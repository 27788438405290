import { DahsboardTabs } from '@/components/dashboard/tabs';
import { AppPageLayout } from '@/layouts/AppPageLayout';

export const DashboardPage = () => {
  return (
    <AppPageLayout
      title="Dashboard"
      description="Statistiques issues de l'application"
    >
      <div className="flex flex-col items-start justify-start h-full py-12 w-full">
        <DahsboardTabs />
      </div>
    </AppPageLayout>
  );
};
