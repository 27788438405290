import { ReactNode, useState } from 'react';
import { ACCESS_TOKEN_KEY } from '@/config/axios.config';
import { AuthContext } from '@/providers/auth.context';

type Props = {
  children: ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem(ACCESS_TOKEN_KEY),
  );

  const createSession = (token: string) => {
    setAccessToken(token);
  };

  const removeSession = () => {
    setAccessToken(null);
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        createSession,
        removeSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
