import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
} from 'recharts';
import { TimeScale } from '@repo/types/src/dashboard';
import { useState } from 'react';
import {
  ChartContainer,
  ChartTooltipContent,
  ChartTooltip,
  type ChartConfig,
} from '@/components/ui/chart';
import { useGetActiveUsersByTimeScale } from '@/api/dashboard/dashboard.hooks';
import { ChartSkeleton } from '@/components/dashboard/chart-skeleton';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { TimeScaleFr } from '@/components/dashboard/utils';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

const commonChartConfig = {
  label: {
    color: 'white',
    label: 'Période',
  },
  activeUsers: {
    label: "Nombre d'utilisatrices actives",
    color: 'hsl(var(--chart-1))',
  },
};

const weekChartConfig = {
  0: { label: 'Lundi' },
  1: { label: 'Mardi' },
  2: { label: 'Mercredi' },
  3: { label: 'Jeudi' },
  4: { label: 'Vendredi' },
  5: { label: 'Samedi' },
  6: { label: 'Dimanche' },
} satisfies ChartConfig;

const monthChartConfig = {
  0: { label: 'S1' },
  1: { label: 'S2' },
  2: { label: 'S3' },
  3: { label: 'S4' },
  4: { label: 'S5' },
} satisfies ChartConfig;

const yearChartConfig = {
  0: { label: 'Janvier' },
  1: { label: 'Février' },
  2: { label: 'Mars' },
  3: { label: 'Avril' },
  4: { label: 'Mai' },
  5: { label: 'Juin' },
  6: { label: 'Juillet' },
  7: { label: 'Août' },
  8: { label: 'Septembre' },
  9: { label: 'Octobre' },
  10: { label: 'Novembre' },
  11: { label: 'Décembre' },
} satisfies ChartConfig;

export const UserRetentionBarChart = () => {
  const [timeScale, setTimeScale] = useState<TimeScale>('week');
  const { distribution, usersCount, isError, isLoading } =
    useGetActiveUsersByTimeScale(timeScale);

  const getChartConfig = () => {
    let timeScaleConfig: ChartConfig = weekChartConfig;
    if (timeScale === 'month') timeScaleConfig = monthChartConfig;
    if (timeScale === 'year') timeScaleConfig = yearChartConfig;

    return { ...commonChartConfig, ...timeScaleConfig };
  };

  const formatPercentage = (value: number) => {
    if (!usersCount) return value;
    return `${Math.floor((value / usersCount) * 100).toFixed(0)} %`;
  };

  const formattedDistribution = distribution?.map(
    ({ timeKey, activeUsers }) => {
      const chartConfig = getChartConfig();
      const configKey = timeKey.toString() as keyof typeof chartConfig;
      const config = chartConfig[configKey];

      return {
        timeKey: config?.label,
        activeUsers,
      };
    },
  );

  const getActiveUsers = () => {
    if (!formattedDistribution || !usersCount) return 0;
    const activeUsers = formattedDistribution.reduce(
      (acc, curr) => acc + curr.activeUsers,
      0,
    );
    const avgActiveUsers = Math.floor(
      activeUsers / formattedDistribution.length,
    );
    return {
      activeUsers: avgActiveUsers,
      avgActiveUsers: Math.floor((avgActiveUsers / usersCount) * 100).toFixed(
        0,
      ),
    };
  };
  const avgActiveUsers = getActiveUsers();

  return (
    <div className="w-full h-full">
      {isLoading || isError ? (
        <ChartSkeleton />
      ) : (
        <Card className="h-full">
          <CardHeader>
            <CardTitle>Taux de rétention des utilisatrices</CardTitle>
            <CardDescription>
              Nombre et pourcentage d'utilisatrices actives sur la période
              choisie
            </CardDescription>
            <div className="w-full flex flex-col xl:flex-row gap-4 items-end pt-3">
              <Select
                value={timeScale}
                onValueChange={(value) => setTimeScale(value as TimeScale)}
              >
                <SelectTrigger className="w-[250px]">
                  <SelectValue placeholder="Sélectionner une période" />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(TimeScaleFr).map(([key, value]) => (
                    <SelectItem key={key} value={key}>
                      {value}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </CardHeader>
          <CardContent>
            <ChartContainer config={getChartConfig()} className="max-h-[500px]">
              <BarChart
                accessibilityLayer
                data={formattedDistribution}
                layout="vertical"
                margin={{ top: 16, bottom: 18, left: 16, right: 16 }}
                maxBarSize={50}
              >
                <CartesianGrid horizontal={false} />
                <XAxis
                  type="number"
                  dataKey="activeUsers"
                  label={{
                    value: "Nombre d'utilisatrices actives",
                    position: 'insideBottom',
                    dy: 20,
                  }}
                  allowDecimals={false}
                  tickLine={false}
                  tickMargin={10}
                  axisLine={false}
                  interval={0}
                />
                <YAxis
                  dataKey="timeKey"
                  type="category"
                  tickLine={false}
                  tickMargin={10}
                  axisLine={false}
                  hide
                  interval={0}
                />
                <ChartTooltip
                  cursor={false}
                  content={
                    <ChartTooltipContent
                      indicator="line"
                      labelFormatter={(value: string) => value}
                    />
                  }
                />

                <Bar
                  dataKey="activeUsers"
                  radius={4}
                  layout="vertical"
                  fill="var(--color-activeUsers)"
                >
                  <LabelList
                    dataKey="timeKey"
                    position="insideLeft"
                    offset={8}
                    className="fill-[--color-label]"
                    fontSize={12}
                  />
                  <LabelList
                    dataKey="activeUsers"
                    position="right"
                    offset={-60}
                    className="fill-[--color-label]"
                    fontSize={12}
                    formatter={formatPercentage}
                  />
                </Bar>
              </BarChart>
            </ChartContainer>
          </CardContent>
          {avgActiveUsers && (
            <CardFooter className="justify-center">
              <p className="font-semibold text-lg text-center leading-none">
                Sur cette période, le nombre d'utilisatrices actives est en
                moyenne de {avgActiveUsers.activeUsers}{' '}
                {avgActiveUsers.activeUsers > 1
                  ? 'utilisatrices'
                  : 'utilisatrice'}{' '}
                sur {usersCount} soit {avgActiveUsers.avgActiveUsers}%
              </p>
            </CardFooter>
          )}
        </Card>
      )}
    </div>
  );
};
