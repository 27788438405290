import { Tabs, TabsList, TabsTrigger, TabsContent } from '@radix-ui/react-tabs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { DashboardTabs, DashboardTabsType } from '@/components/dashboard/utils';
import { UserDashboard } from '@/components/dashboard/user/user-dashboard';
import { UnderwearDashboard } from '@/components/dashboard/underwear/underwear-dashboard';
import { AnalyticsDashboard } from '@/components/dashboard/analytics/analytics-dashboard';

const TAB_SEARCH_PARAM = 'tab';
const DEFAULT_TAB = 'user';

const getActiveTabIndex = (tab: DashboardTabsType) => {
  if (tab === 'user') return 0;
  if (tab === 'underwear') return 1;
  if (tab === 'app_perf') return 2;
  return 0;
};

export const DahsboardTabs = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const initialTab =
    (searchParams.get(TAB_SEARCH_PARAM) as DashboardTabsType) ?? DEFAULT_TAB;
  const [activeTabIndex, setActiveTabIndex] = useState(() =>
    getActiveTabIndex(initialTab),
  );

  const handleTabChange = (value: DashboardTabsType) => {
    const tabIndex = getActiveTabIndex(value);
    setActiveTabIndex(tabIndex);

    const newSearchParams = new URLSearchParams();
    newSearchParams.set(TAB_SEARCH_PARAM, value);

    navigate({ search: newSearchParams.toString() });
  };

  return (
    <Tabs
      defaultValue={initialTab}
      className="w-full h-full"
      tabIndex={activeTabIndex}
      onValueChange={(value) => handleTabChange(value as DashboardTabsType)}
    >
      <TabsList className="w-full flex-row flex justify-around min-h-12 border-b-2">
        {Object.entries(DashboardTabs).map(([key, value]) => (
          <TabsTrigger
            key={key}
            value={key}
            className="text-center flex-grow line-clamp-2 font-medium data-[state=active]:font-bold data-[state=active]:bg-zinc-100 py-2 px-4 rounded-md data-[state=active]:shadow-sm"
          >
            {value}
          </TabsTrigger>
        ))}
      </TabsList>
      <div className="h-full w-full">
        <TabsContent value="user" className="h-full w-full overflow-y-auto">
          <UserDashboard />
        </TabsContent>
        <TabsContent
          value="underwear"
          className="h-full w-full overflow-y-auto"
        >
          <UnderwearDashboard />
        </TabsContent>
        <TabsContent value="app_perf" className="h-full w-full overflow-y-auto">
          <AnalyticsDashboard />
        </TabsContent>
      </div>
    </Tabs>
  );
};
