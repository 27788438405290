import { Pie, PieChart } from 'recharts';
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import { useGetFlowDistribution } from '@/api/dashboard/dashboard.hooks';
import { ChartSkeleton } from '@/components/dashboard/chart-skeleton';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from '@/components/ui/card';

const chartConfig = {
  users: {
    label: 'Utilisatrices',
  },
  LIGHT: {
    label: 'Léger',
    color: 'hsl(var(--chart-2))',
  },
  MODERATE: {
    label: 'Modéré',
    color: 'hsl(var(--chart-4))',
  },
  HEAVY: {
    label: 'Abondant',
    color: 'hsl(var(--chart-3))',
  },
  HEMORRHAGIC: {
    label: 'Hémorragique',
    color: 'hsl(var(--chart-1))',
  },
} satisfies ChartConfig;

export const FlowPieChart = () => {
  const { flowDistribution, isError, isLoading } = useGetFlowDistribution();

  const flowDistributionWithColors = flowDistribution?.map((flow) => {
    return {
      ...flow,
      fill: `var(--color-${flow.flow})`,
    };
  });

  const usersCount = flowDistribution?.reduce(
    (acc, curr) => acc + curr.users,
    0,
  );

  return (
    <div className="w-full h-full">
      {isLoading || isError ? (
        <ChartSkeleton />
      ) : (
        <Card>
          <CardHeader>
            <CardTitle>Répartition des utilisatrices par flux</CardTitle>
            <CardDescription>
              Le flux mentionné correspond au flux renseigné lors de
              l'inscription.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <ChartContainer
              config={chartConfig}
              className="mx-auto aspect-square"
            >
              <PieChart
                margin={{
                  top: 20,
                }}
              >
                <ChartTooltip
                  content={<ChartTooltipContent nameKey="flow" hideLabel />}
                />
                <Pie
                  data={flowDistributionWithColors}
                  dataKey="users"
                  labelLine={false}
                  label={({ payload, ...props }) => {
                    return (
                      <text
                        cx={props.cx}
                        cy={props.cy}
                        x={props.x}
                        y={props.y}
                        textAnchor={props.textAnchor}
                        dominantBaseline={props.dominantBaseline}
                        fill="hsla(var(--foreground))"
                      >
                        {usersCount && payload.users
                          ? `${Math.round((payload.users / usersCount) * 100)}%`
                          : ''}
                      </text>
                    );
                  }}
                  nameKey="flow"
                />
                <ChartLegend
                  content={<ChartLegendContent nameKey="flow" />}
                  className="flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center"
                />
              </PieChart>
            </ChartContainer>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
